/* Google Font Import - Poppins */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');


:root {
    /* ===== Colors ===== */
    --admin_sidebar-color: #FFF;
    --primary-color: #F4F2FF;
    --primary-color-light: #F6F5FF;
    --toggle-color: #DDD;
    --text-color: #707070;

    /* ====== Transition ====== */
    --tran-03: all 0.2s ease;
    --tran-03: all 0.3s ease;
    --tran-04: all 0.3s ease;
    --tran-05: all 0.3s ease;
}


/* ===== Reusable code - Here ===== */

.flex_around {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.searchbox {
    padding: 8px;
}

.admin_sidebar {
    width: 360px;
    background: #FBFAF6;
    transition: var(--tran-05);
    z-index: 1;
    min-height: 100vh;
}

.admin_sidebar .logo_img_wrapper {
    padding-left: 25px;
    width: 150px;
}

.admin_sidebar li {
    list-style: none;
}

.admin_sidebar .icon {
    min-width: 70px;
    color: #444;
    font-size: 20px;
}

.admin_sidebar .text {
    color: #444;
}

.admin_sidebar .text {
    font-size: 17px;
    font-weight: 500;
    white-space: nowrap;
    opacity: 1;
}

.admin_sidebar.close .text {
    opacity: 0;
}

/* =========================== */

.admin_sidebar header {
    position: relative;
}

.admin_sidebar header .image {
    display: flex;
    align-items: center;
    justify-content: center;
}

.admin_sidebar header .image img {
    width: 40px;
    border-radius: 6px;
}

.admin_sidebar header .toggle {
    position: absolute;
    top: 15px;
    right: -55px;
    transform: translateY(-50%) rotate(180deg);
    width: 25px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 35px;
    cursor: pointer;
    transition: var(--tran-05);
}

body.dark .admin_sidebar header .toggle {
    color: var(--text-color);
}

.admin_sidebar.close .toggle {
    transform: translateY(-50%) rotate(0deg);
}


.admin_sidebar li a {
    list-style: none;
    height: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    border-radius: 6px;
    text-decoration: none;
    transition: var(--tran-03);
    padding: 6px 0px;
}

.admin_sidebar li:hover,
.admin_sidebar li:hover .nav-text,
.admin_sidebar li:hover svg {
    background-color: var(--primary-color);
    border-radius: 6px;
    color: #4936B6;
}

.admin_sidebar .menu-bar .activeLink {
    background-color: var(--primary-color);
    border-radius: 6px;
}

.admin_sidebar .menu-bar .activeLink svg,
.admin_sidebar .menu-bar .activeLink .nav-text {
    color: #4936B6;
}

.admin_sidebar .menu-bar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
}

.admin_sidebar .nav-link {
    padding: 8px;
    cursor: pointer;
}

.admin_sidebar .dropdown_menu_item a {
    text-decoration: none;
}

.menu-bar::-webkit-scrollbar {
    display: none;
}


.admin__dashboard .rightbar {
    /* max-height: 100vh; */
    /* overflow-y: scroll; */
    width: 100%;
    background-color: #F1F2F6;
    transition: var(--tran-05);
}

/* ===== admin_sidebar ===== */

.admin_sidebar.close {
    width: 88px;
}

.admin_sidebar.close .menu-bar {
    padding: 0;
    transition: var(--tran-03);
}

.menu .dropdown-menu.show {
    inset: 16px auto auto 11px;
    background: transparent;
    border: none;
}

.menu .dropdown-menu.show a:hover {
    background-color: var(--primary-color);
    border-radius: 6px;
}

.dropdown_menu {
    cursor: pointer;
    color: #444;
}

.dropdown_menu_item p {
    padding-left: 30%;
    /* border-bottom: 1px solid #F6F5FF; */
    background-color: #e0e0de;
}

.dropdown_menu_item .collapse.show p:hover {
    background: #cecece;
}

.rotate_icon {
    transform: rotate(180deg);
}

@media screen and (max-width: 480px) {
    .admin_sidebar {
        width: 88px;
    }

    .admin_sidebar .text {
        opacity: 0;
    }

    .copyright span {
        margin-left: 100px;
        font-size: 13px;
    }
}

@media screen and (min-width: 481px) and (min-width: 768px) {
    .copyright span {
        margin-left: 150px;
        font-size: 15px;
    }
}