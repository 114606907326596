.navbar-wrapper {
    position: relative;
    background: #53746e;
    padding: 21px 30px;
    display: flex;
    flex-wrap: wrap;
    transition: all 0.5s cubic-bezier(0.4, -0.25, 0.25, 1.1);
}

.navbar-right li {
    list-style: none;
}

.navbar-right .fullscreen-btn {
    text-shadow: 1px 2px 5px rgba(0, 0, 0, 0.15);
    font-size: 1.5rem;
}

.navbar-right .fa-icon {
    margin-right: 15px;
    color: #f3f5f7;
    font-size: 23px;
}

.body-wrapper {
    transition: all 0.5s cubic-bezier(0.4, -0.25, 0.25, 1.1);
}

.body-wrapper .topbar {
    background: #FFFFFF;
    color: #444;
    padding: 12px 8px;
}

.body-wrapper .main_section {
    padding: 0px 30px;
}

.body-wrapper .search_icon {
    position: absolute;
    top: 25%;
    left: 13px;
    color: #999;
}

.form-control.form_control {
    padding: 6px 10px 6px 38px;
    border-radius: 12px;
}

.form-control.form_control::placeholder {
    font-size: 18px;
    font-weight: 500;
    color: #999;
}

.admin_profile_dropdown .dropdown-toggle::after {
    display: none;
}

.admin_profile_dropdown .dropdown-menu.show {
    inset: 25px 0px auto auto !important;
    border: none;
    border-radius: 12px;
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 12px;
    transform: translate(0, 55px) !important;
    transition: transform 0.3s ease-in-out !important;
}

.topbar .admin_profile_logo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #777;
}

.topbar .dropdown-item {
    padding: 0.7rem 0.7rem;
    font-weight: 600;
}

.topbar .dropdown-item:focus,
.topbar .dropdown-item:hover {
    background-color: #fff;
    color: unset;
}

.topbar .dropdown-item:not(:first-child):hover {
    background-color: var(--primary-color);
    border-radius: 6px;
    color: #4936B6;
}

.topbar .dropdown-menu {
    padding: 12px 15px;
}

.arrow-up {
    position: absolute;
    width: 0;
    height: 0;
    top: -1.5%;
    right: 24px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #fff;
}

.body-wrapper .dashboard_card {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 15px;
    border: none;
    box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
    transition: all 0.3s ease;
}

.body-wrapper .dashboard_card:hover {
    transform: scale(1.1);
}

.body-wrapper .dashboard_card .widget {
    display: flex;
    align-items: center;
    transition: all 0.3s;
    padding: 30px 26px;
    border-radius: 13px;
    border-bottom: 9px solid #53746e;
}

.body-wrapper .dashboard_card .widget .widget-icon {
    width: 70px;
    height: 70px;
    display: flex;
    align-self: flex-start;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.body-wrapper .dashboard_card .widget .widget-icon .w-icon {
    color: #53746e;
    font-size: 64px;
}

.body-wrapper .dashboard_card .widget .widget-content {
    width: 100%;
    padding-left: 15px;
}

.body-wrapper .dashboard_card .widget .widget-content .text {
    color: #6c757d !important;
}

.body-wrapper .dashboard_card .widget .widget-content .text-dark {
    color: #53746e !important;
    font-weight: 700;
}

.body-wrapper .dashboard_card .widget .widget-content .btn {
    background: #e7c6c2;
    color: #53746e;
    font-weight: 600;
    margin-top: 6px;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
}

.body-wrapper .dashboard_card .widget .widget-content .btn:focus {
    box-shadow: none;
}

.body-wrapper .dashboard_card .widget .widget-content .btn:hover {
    color: black;
}


@media screen and (max-width: 576px) {

    .form-control.form_control::placeholder {
        font-size: 16px;
    }

    .body-wrapper .dashboard_card .widget .widget-icon {
        width: 40px;
        height: auto;
    }

    .widget-content .widget-title {
        font-size: 12px !important;
    }

    .body-wrapper .dashboard_card .widget .widget-content .btn {
        font-size: 13px;
    }
}

@media screen and (max-width:768px) {}